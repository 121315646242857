import { defineStore } from 'pinia';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useProfileStore } from './profile.store';
import { useUserStore } from '@/store/pinia/user.store.js';

export const useProfitabilityStore = defineStore('profitability', () => {
  const loadingStore = useLoadingStore();
  const mainStore = useMainStore();
  const profileStore = useProfileStore();
  const userStore = useUserStore();

  // --- Methods ---
  const getHubList = async () => {
    const params = {
      endpoint: `v2/api/profitability/resources/hubs`,
      configs: {
        method: 'GET',
      },
    };
    loadingStore.startGlobalLoading('getHubList');
    try {
      return await mainStore.request(params);
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endGlobalLoading('getHubList');
    }
  };

  const getFirstSelectedHub = async () => {
    loadingStore.startGlobalLoading('getFirstSelectedHub');

    try {
      const profileMenuInformation =
        await profileStore.getProfileMenuInformation(userStore.userInfo.sub);

      return (
        profileMenuInformation.offices?.mainOffice?.id ||
        profileMenuInformation.offices?.secondaryOffice[0]?.id
      );
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endGlobalLoading('getFirstSelectedHub');
    }
  };

  return {
    getHubList,
    getFirstSelectedHub,
  };
});
