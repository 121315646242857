<template data-testid="tour-prescription-card">
  <v-card class="rounded-lg pa-4" color="ashLight" elevation="0">
    <v-card-text>
      <v-container class="pa-0">
        <v-row class="mb-4">
          <v-col class="pa-0">
            <RegularBadge
              :color="badgeColors.color"
              :text-color="badgeColors.text"
              :text="getBadgeMessage"
              data-testid="tour-prescription-card-type-badge"
              class="caption-2"
            />
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-0 d-flex align-center">
            <span
              class="text-truncate body-text-3 mineBlack--text"
              data-testid="tour-prescription-card-patient-name-field"
            >
              {{ prescription.patientDetails?.name || '-' }}
            </span>
          </v-col>
        </v-row>
        <v-row
          class="my-4 caption-1 greyRegular--text d-flex justify-space-between"
        >
          <v-col
            cols="auto"
            class="pa-0"
            data-testid="tour-prescription-card-start-date-field"
          >
            {{
              helpers.formatDate(prescription.prescriptionDetails?.startDate) ||
              ''
            }}
          </v-col>
          <v-col class="pa-0 d-flex justify-center">
            <v-icon class="px-1 caption-1" size="12" color="greyRegular">
              mdi-arrow-right
            </v-icon>
          </v-col>
          <v-col
            cols="auto"
            class="pa-0"
            data-testid="tour-prescription-card-end-date-field"
          >
            {{
              helpers.formatDate(prescription.prescriptionDetails?.endDate) ||
              ''
            }}
          </v-col>
        </v-row>
        <v-row class="mb-4 greyRegular--text body-4">
          <v-col class="d-flex align-center pa-0 mb-2">
            <v-icon color="greyRegular" size="18"> mdi-medical-bag </v-icon>
            <span
              class="pl-2 text-truncate"
              data-testid="tour-prescription-card-doctor-name-field"
            >
              {{ prescription.prescriptionDetails?.doctorDetails?.name || '-' }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center pa-0">
            <a
              class="button-1 text-center pt-3"
              @click="openDetails"
              data-testid="tour-prescription-card-view-details-link"
            >
              {{ t('Msg_.seeServices') }}
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <TourPrescriptionDialog
      :display-dialog="displayDialog"
      :prescription="prescription"
      @close-dialog="closeDialog()"
    />
  </v-card>
</template>
<script setup>
import { ref, computed, defineProps } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import languages from '../locales/languages.js';
import RegularBadge from '@/components/shared/customComponents/alertsBadges/regular-badge.vue';
import TourPrescriptionDialog from './tour-prescription-dialog.vue';

const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  prescription: {
    type: Object,
    required: true,
  },
});

// --- Local Variables ---
const displayDialog = ref(false);
const prescriptionTypes = {
  expiring: 2,
  missing: 3,
  expired: 4,
};

// --- Methods ---
const openDetails = () => {
  displayDialog.value = true;
};

const closeDialog = () => {
  displayDialog.value = false;
};

const getExpiringMessage = () => {
  const days = props.prescription.prescriptionDetails.daysUntilExpire;
  if (days < 1) {
    return t('Msg_.expiresToday');
  }
  return t(`Msg_.expiresIn`, { days: Math.floor(days) });
};

// --- Computed ---
const getBadgeMessage = computed(() => {
  switch (props.prescription.status.Value) {
    case prescriptionTypes.expiring:
      return getExpiringMessage();

    case prescriptionTypes.missing:
      return t('Msg_.missing');
    case prescriptionTypes.expired:
      return t('Msg_.expired');
    default:
      return '';
  }
});

const badgeColors = computed(() => {
  if (props.prescription.status.Value === prescriptionTypes.expiring) {
    return {
      text: 'yellowDark',
      color: 'yellowLight',
    };
  }
  return {
    text: 'redDanger',
    color: 'redErrorLight',
  };
});
</script>
<style lang="scss" scoped></style>
