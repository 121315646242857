import { render, staticRenderFns } from "./tour-prescription-card.vue?vue&type=template&id=99f0d1ac&scoped=true&data-testid=tour-prescription-card&"
import script from "./tour-prescription-card.vue?vue&type=script&setup=true&lang=js&"
export * from "./tour-prescription-card.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99f0d1ac",
  null
  
)

export default component.exports