import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { useMainStore } from '@/store/pinia/main.store.js';
import helpers from '@/helpers/helpers';
import { useProfitabilityStore } from './profitability.store';

export const useToursStore = defineStore('tours', () => {
  const loadingStore = useLoadingStore();
  const mainStore = useMainStore();
  const profitabilityStore = useProfitabilityStore();

  // --- State ---
  const employeeTours = ref([]);
  const comparisonTours = ref([]);
  const tourDetails = ref(null);
  const tourComparison = ref(null);
  const hubList = ref([]);
  const tourDataInitialized = ref(false);

  const profitabilitySettings = ref({
    overheadCost: null,
    totalNumberOfNurses: null,
    totalNumberOfWorkingHours: null,
  });

  const overheadCostReset = ref(null);
  const selectedDate = ref(null);
  const selectedTour = ref(null);
  const selectedComparisonTour = ref(null);
  const selectedHub = ref(null);
  const hubIsEligible = ref(false);

  // --- Methods ---
  const getTours = async () => {
    if (!hubIsEligible.value) {
      return;
    }

    const params = {
      endpoint: `v2/api/profitability/${selectedHub.value}/${selectedDate.value}/${selectedDate.value}`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getTours');

    try {
      employeeTours.value = await mainStore.request(params);
      await getProfitabilitySettings();
      const shiftId = getIdForSelectedTour();
      if (shiftId) {
        await getTourDetails(shiftId);
      }
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endGlobalLoading('getTours');
    }
  };

  const getIdForSelectedTour = () => {
    let id = null;
    if (employeeTours.value && employeeTours.value.length > 0) {
      const sameTourLastSelected = employeeTours.value.find((tour) => {
        return tour.shiftId === selectedTour.value;
      });

      id = sameTourLastSelected
        ? sameTourLastSelected.shiftId
        : employeeTours.value[0].shiftId;
    }
    return id;
  };

  const getTourDetails = async (id) => {
    selectedTour.value = id;
    const params = {
      endpoint: `v2/api/profitability/${selectedTour.value}/${selectedHub.value}/${selectedDate.value}/${selectedDate.value}`,

      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getTourDetails');

    try {
      tourDetails.value = await mainStore.request(params);
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endPartialLoading('getTourDetails');
    }
  };

  const getHubList = async () => {
    loadingStore.startGlobalLoading('toursGetHubList');
    try {
      hubList.value = await profitabilityStore.getHubList();
      if (hubList.value.length > 1) {
        const office = await profitabilityStore.getFirstSelectedHub();

        const officeInHubList = hubList.value.find((hub) => hub.id === office);
        if (officeInHubList) {
          setHub(office);
        } else {
          setHub(hubList.value[0].id);
        }
      } else if (hubList.value[0]) {
        setHub(hubList.value[0].id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endGlobalLoading('toursGetHubList');
    }
  };

  const getProfitabilitySettings = async () => {
    // JavaScript Date Api returns month as a index from 0 to 11, so, need to increase +1
    const month = new Date(selectedDate.value).getMonth() + 1;
    const year = new Date(selectedDate.value).getFullYear();

    const params = {
      endpoint: `v2/api/ProfitabilitySettings/${selectedHub.value}?month=${month}&year=${year}`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getProfitabilitySettings');

    try {
      const res = await mainStore.request(params);
      profitabilitySettings.value.totalNumberOfNurses = res.totalNumberOfNurses;
      profitabilitySettings.value.totalNumberOfWorkingHours =
        res.totalNumberOfWorkingHours;

      if (Array.isArray(res.overheadCosts) && res.overheadCosts.length > 0) {
        overheadCostReset.value = res.overheadCosts[0].overheadCost;
        profitabilitySettings.value.overheadCost =
          helpers.convertValueToCurrency(overheadCostReset.value);
      } else {
        profitabilitySettings.value.overheadCost = null;
        overheadCostReset.value = null;
      }
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endGlobalLoading('getProfitabilitySettings');
    }
  };

  const submitOverheadCost = async () => {
    // JavaScript Date Api returns month as a index from 0 to 11, so, need to increase +1
    const payload = {
      month: new Date(selectedDate.value).getMonth() + 1,
      year: new Date(selectedDate.value).getFullYear(),
      overheadCost: helpers.convertCurrencyToFloat(
        profitabilitySettings.value.overheadCost
      ),
    };

    const params = {
      endpoint: `v2/api/ProfitabilitySettings/hubOverheadCost/${selectedHub.value}`,
      configs: {
        method: 'POST',
        body: payload,
      },
    };

    try {
      await mainStore.request(params);
      overheadCostReset.value = profitabilitySettings.value.overheadCost;
      await getTours();
    } catch (error) {
      console.log(error);
    }
  };

  const getHubEligibility = () => {
    const eligibleHubIds = hubList.value.map((hub) => hub.id);
    hubIsEligible.value = eligibleHubIds.includes(selectedHub.value);
  };

  const setHub = (hub) => {
    selectedHub.value = hub;
    getHubEligibility();
  };

  const getToursComparison = async () => {
    if (!hubIsEligible.value) {
      return;
    }
    const params = {
      endpoint: `v2/api/hubs/${selectedHub.value}/profitability-comparison?date=${selectedDate.value}`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getToursComparison');

    try {
      comparisonTours.value = await mainStore.request(params);
      const shiftId = getIdForSelectedComparisonTour();
      if (shiftId) {
        await getTourComparisonDetails(shiftId);
      }
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endGlobalLoading('getToursComparison');
    }
  };

  const getIdForSelectedComparisonTour = () => {
    let id = null;
    if (!comparisonTours.value || comparisonTours.value.length === 0) {
      return;
    }
    const toursWithComparisonInformation = comparisonTours.value.filter(
      (tour) => !!tour.hasActualInformation
    );
    if (toursWithComparisonInformation.length > 0) {
      const sameTourLastSelected = toursWithComparisonInformation.find(
        (tour) => {
          return tour.shiftId === selectedComparisonTour.value;
        }
      );
      const shiftId = sameTourLastSelected
        ? sameTourLastSelected.shiftId
        : toursWithComparisonInformation[0].shiftId;
      id = shiftId;
    }
    return id;
  };

  const getTourComparisonDetails = async (id) => {
    selectedComparisonTour.value = id;
    const params = {
      endpoint: `v2/api/hubs/${selectedHub.value}/shifts/${selectedComparisonTour.value}/profitability-comparison?date=${selectedDate.value}`,
      configs: {
        method: 'GET',
      },
    };
    loadingStore.startPartialLoading('getTourComparisonDetails');

    try {
      tourComparison.value = await mainStore.request(params);
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endPartialLoading('getTourComparisonDetails');
    }
  };

  return {
    employeeTours,
    hubIsEligible,
    tourDetails,
    hubList,
    profitabilitySettings,
    selectedDate,
    selectedTour,
    selectedComparisonTour,
    selectedHub,
    overheadCostReset,
    tourComparison,
    comparisonTours,
    tourDataInitialized,
    getToursComparison,
    getTourComparisonDetails,
    getTours,
    getTourDetails,
    getHubList,
    getProfitabilitySettings,
    submitOverheadCost,
    setHub,
  };
});
