<template data-testid="tour-prescription-dialog">
  <v-dialog
    :value="displayDialog"
    width="454"
    data-testid="tour-prescription-dialog"
  >
    <v-card>
      <v-card-text class="pa-0">
        <v-container class="px-8 py-6">
          <v-row class="ma-0 pb-4">
            <v-col
              class="pa-0 heading-h4"
              data-testid="tour-prescription-dialog-patient-name-field"
            >
              {{ prescription.patientDetails?.name || '-' }}
            </v-col>
            <v-col class="pa-0" cols="auto">
              <v-icon
                size="28"
                @click="closeDialog()"
                color="mineBlack"
                data-testid="tour-prescription-dialog-close-icon"
              >
                mdi-close
              </v-icon>
            </v-col>
          </v-row>

          <v-row class="ma-0 pb-4">
            <v-col class="pa-0">
              <RegularBadge
                :color="badgeColors.color"
                class="body-text-4"
                :text-color="badgeColors.text"
                :text="getBadgeMessage()"
                :data-testid="`tour-prescription-dialog-type-badge`"
              />
            </v-col>
          </v-row>

          <v-row class="ma-0 pb-8">
            <v-col
              cols="auto"
              class="pa-0 d-flex justify-space-between body-text-4 greyRegular--text"
              data-testid="tour-prescription-dialog-start-date-field"
            >
              {{
                helpers.formatDate(
                  prescription.prescriptionDetails?.startDate
                ) || ''
              }}

              <v-icon class="px-3" size="12" color="greyRegular">
                mdi-arrow-right
              </v-icon>
              <div
                cols="auto"
                class="pa-0"
                data-testid="tour-prescription-dialog-end-date-field"
              >
                {{
                  helpers.formatDate(
                    prescription.prescriptionDetails?.endDate
                  ) || ''
                }}
              </div>
            </v-col>
          </v-row>

          <v-row class="ma-0 pb-4">
            <v-col class="pa-0 overline-2 greyDark--text">
              {{ t('Title_.subTitle.services') }}
            </v-col>
          </v-row>

          <v-row
            v-for="(service, index) in prescription.services"
            :key="`service-${index}`"
            class="ma-0 pb-2"
          >
            <v-col
              class="pa-0 body-text-2"
              :data-testid="`tour-prescription-dialog-service-${index}-field`"
            >
              {{ service.designation || '-' }}
            </v-col>
          </v-row>
          <v-row class="ma-0 pt-6">
            <v-col
              class="d-flex align-center pa-0 mb-2 greyRegular--text body-4"
            >
              <v-icon size="18" color="greyRegular"> mdi-medical-bag </v-icon>
              <span
                class="pl-2"
                data-testid="tour-prescription-card-doctor-name-field"
              >
                {{
                  prescription.prescriptionDetails?.doctorDetails?.name || '-'
                }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import languages from '../locales/languages';
import RegularBadge from '@/components/shared/customComponents/alertsBadges/regular-badge.vue';

const { t } = useI18n({ locale: 'en', messages: languages });
const emit = defineEmits(['close-dialog']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  prescription: {
    type: Object,
    required: true,
    default: () => {},
  },
  displayDialog: {
    type: Boolean,
    required: true,
  },
});

// --- Local variables ---
const prescriptionTypes = {
  expiring: 2,
  missing: 3,
  expired: 4,
};

// --- Methods ---
const closeDialog = () => {
  emit('close-dialog');
};

const getBadgeMessage = () => {
  switch (props.prescription.status?.Value) {
    case prescriptionTypes['expiring']:
      return getExpiringMessage();
    case prescriptionTypes['expired']:
      return getExpiredMessage();
    case prescriptionTypes['missing']:
      return t('Msg_.missing');
    default:
      return '';
  }
};

const getExpiringMessage = () => {
  let expireInDays = parseFloat(
    props.prescription.prescriptionDetails?.daysUntilExpire
  );
  if (expireInDays < 1) {
    return t('Msg_.expiresToday');
  }
  return t('Msg_.expiresIn', { days: Math.floor(expireInDays) || '-' });
};

const getExpiredMessage = () => {
  let expiredForDays = parseFloat(
    props.prescription.prescriptionDetails?.daysSinceExpire
  );
  return t('Msg_.expiredFor', { days: Math.ceil(expiredForDays) || '-' });
};

// --- Computed ---
const badgeColors = computed(() => {
  if (props.prescription.status?.Value === prescriptionTypes.expiring) {
    return {
      text: 'yellowDark',
      color: 'yellowLight',
    };
  }
  return {
    text: 'redDanger',
    color: 'redErrorLight',
  };
});
</script>

<style lang="scss" scoped></style>
